@import '../assets/styles/app/variable.scss';


.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  

  .videoContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;

    video {
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      content: '';
      clear: both;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($white, 0.5);
      z-index: 1;
    }
  }

  .content {
    width: 100%;
    max-width: 340px;
    border-radius: 4px;
    box-shadow: 2px 2px 8px rgba(white, .2);
  }
}


