@import './assets/styles/app/reset.scss';
@import './assets/styles/app/variable.scss';

body, html {
  height: 100%;
}

#root {
  height: inherit;
}

body {
  margin: 0;
  font-family: $font-family-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-base;

  color: white;
  // animation: text-color-change 0.1s infinite;
  font-size: 16px;
  line-height: 24px;
  // font-family: monospace;
  margin: 0;
  padding: 0;
  // text-shadow: 0 0 3px $font-text-shadow;

  &:before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $background-gradient;
    // background-color: $green-dark-3;
    z-index: -1;
    content: '';
  }
}

h1 { font-size: $h1-font-size }
h2 { font-size: $h2-font-size }
h3 { font-size: $h3-font-size }
h4 { font-size: $h4-font-size }
h5 { font-size: $h5-font-size }
h6 { font-size: $h6-font-size }

button {
  padding: 0;
  color: white;
  background: transparent;
  border: none;
  // text-shadow: 0 0 3px $font-text-shadow;
  // animation: text-color-change 0.1s infinite;
}

a {
  color: $white;
  // animation: text-color-change 0.1s infinite;
  text-decoration: none;

  &:hover {
    // color: $green-dark;
    text-decoration: none;
  }
}

.btn {

  color: $green;
  appearance: none;
  border: none;
  background: none;
  font-size: .8rem;
  font-weight: bold;
  line-height: 2rem;

  &.play {
    display: flex;
    align-items: center;
    
    
    span {
      margin-left: .5rem;
    }
    i:before {
      font-size: 2rem;
    }
  }

  &.launch {
    padding: 0 1.5rem;
    text-transform: uppercase;
    border-radius: 24px;
    border: 1px solid;
    a {
      color: inherit;
    }
  }
}

.paperWrapper {
  position: relative;
  margin: 1rem;
  padding: 2rem 1rem;
  background-color: $white;
  color: $black;
  clip-path: polygon(1.5rem 0%, 100% 0%, 100% 100%, 0% 100%, 0% 1.5rem);

  &:before {
    content: '';
    clear: both;
    display: block;
    width: 0; height: 0;
    position: absolute;
    top: -1px; left: -1px;
    border: 0.8rem solid rgba(0, 0, 0, 0.2);
    border-left-color: transparent;
    border-top-color: transparent;
    border-radius: 0 0 3px 0;
  }

  p, ul {
    padding: 1rem;
  }

  .link {
    color: $blue-dark;
  }

}




@keyframes blinking {
  50% {
    opacity: 0;
  }
}

@keyframes text-color-change {
  10% {
    color: $green-dark-1;
  }
  66% {
    color: $green-dark-2;
  }
}

.p-component {
  font-family: $font-family-base !important;
}

@keyframes marquee{
  0%{transform: translate(0, 0);}
  100%{transform: translate(-100%, 0)}
}




