@font-face {
  font-family: 'Diforb_ui';
  src:  url('fonts/Diforb_ui.eot?n7tcxh');
  src:  url('fonts/Diforb_ui.eot?n7tcxh#iefix') format('embedded-opentype'),
    url('fonts/Diforb_ui.woff2?n7tcxh') format('woff2'),
    url('fonts/Diforb_ui.ttf?n7tcxh') format('truetype'),
    url('fonts/Diforb_ui.woff?n7tcxh') format('woff'),
    url('fonts/Diforb_ui.svg?n7tcxh#Diforb_ui') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Diforb_ui' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-logo:before {
  content: "\e922";
}
.icon-youtube:before {
  content: "\e921";
}
.icon-diforb-logo-slogan:before {
  content: "\e920";
}
.icon-play-radial:before {
  content: "\e91e";
}
.icon-pause-radial:before {
  content: "\e91f";
}
.icon-add-b:before {
  content: "\e91d";
}
.icon-reload:before {
  content: "\e918";
}
.icon-reset2:before {
  content: "\e919";
}
.icon-attention:before {
  content: "\e91a";
}
.icon-attention-line:before {
  content: "\e91b";
}
.icon-twitter:before {
  content: "\e917";
}
.icon-diforb-logo:before {
  content: "\e916";
}
.icon-close-video:before {
  content: "\e911";
}
.icon-document:before {
  content: "\e90d";
}
.icon-document2:before {
  content: "\e90e";
}
.icon-certificate2:before {
  content: "\e906";
}
.icon-certificate:before {
  content: "\e90b";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-google:before {
  content: "\f1a0";
}
.icon-undo:before {
  content: "\e90f";
}
.icon-chevron-up:before {
  content: "\e913";
}
.icon-chevron-down:before {
  content: "\e915";
}
.icon-chevron-left2:before {
  content: "\e912";
}
.icon-chevron-right2:before {
  content: "\e914";
}
.icon-burger:before {
  content: "\c106";
}
.icon-close:before {
  content: "\c108";
}
.icon-add:before {
  content: "\c104";
}
.icon-play2:before {
  content: "\c105";
}
.icon-pause2:before {
  content: "\c107";
}
.icon-volume-off:before {
  content: "\c101";
}
.icon-volume:before {
  content: "\c100";
}
.icon-pause:before {
  content: "\c102";
}
.icon-play:before {
  content: "\c103";
}
.icon-reset:before {
  content: "\e90a";
}
.icon-libs:before {
  content: "\e900";
}
.icon-cog:before {
  content: "\e901";
}
.icon-info:before {
  content: "\e902";
}
.icon-question:before {
  content: "\e903";
}
.icon-avatar:before {
  content: "\e910";
}
.icon-avatar-solid:before {
  content: "\e904";
}
.icon-login:before {
  content: "\e90c";
}
.icon-logout:before {
  content: "\e905";
}
.icon-menu:before {
  content: "\e907";
}
.icon-search:before {
  content: "\e908";
}
.icon-sounds:before {
  content: "\e909";
}
.icon-add-a:before {
  content: "\e91c";
}

