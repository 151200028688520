@import './variable.scss';

.p-menubar	{
  background: transparent;
  border: none;
}
// p-menu-list	
// p-menuitem
// p-menuitem-text
// p-menuitem-icon	
// p-submenu-icon