$easeInSine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000);




// FL studio knobs - demo styles

$envelope-knob-size: 80px;
$tension-knob-size: 60px;



// FL studio knobs - control styles

.fl-studio-envelope__knob {
    flex: 0 0 auto;
    &.envelope-knob {
        width: $envelope-knob-size;
        height: $envelope-knob-size;
    }
    &.tension-knob {
        padding: ($envelope-knob-size - $tension-knob-size)/2;
        width: $envelope-knob-size;
        height: $envelope-knob-size;
    }
    .knob-input__visual {
        overflow: visible;
    }
    .dial {
        transform-origin: 20px 20px;
        transition: all 600ms $easeOutSine;
    }
    .dial-highlight {
        opacity: 0;
        transition: all 300ms $easeOutSine;
    }
    &:hover, &.drag-active {
        .dial {
            transform: scale(0.9);
            filter: brightness(10.2);
            transition: all 300ms $easeOutSine;
        }
        .dial-highlight {
            opacity: 0.05;
        }
    }
    .focus-indicator {
        opacity: 0;
        transition: all 300ms $easeOutSine;
    }
    &.focus-active {
        .focus-indicator {
            opacity: 0.8;
        }
    }
}

// Control Knob component styles
.knob-input {
    position: relative;
}
.knob-input__visual {
    pointer-events: none;
    width: 100%;
    height: 100%;
}
.knob-input__input {
    cursor: grab;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;  
    transform: rotate(-90deg);
}


