

$white: #fff;
$black: #000;
$primary: #00cd99;
$facebook: #5692d1;
$google: #e06746;
$green: #52d669;
$green-dark: #277a54;
$green-blur: #4AB873;
$grey: #9ca5a3;

// $green: #0a0;
// $green-dark: #060;
$green-dark-1: #070;
$green-dark-2: #080;
$green-dark-3: #020;
$blue-blur: #6cc0bd;
$blue-dark: #276585;
$blue-light: #7ab2ba;
$blue-ice: #4ebffb;

$backround-color-black-1: rgba(0, 0, 0, .1);
$backround-color-black-2: rgba(0, 0, 0, .2);
$backround-color-black-3: rgba(0, 0, 0, .3);
$backround-color-black-4: rgba(0, 0, 0, .4);
$backround-color-black-5: rgba(0, 0, 0, .5);
$backround-color-black-6: rgba(0, 0, 0, .6);
$backround-color-black-8: rgba(0, 0, 0, .8);
$background-color-white-85: rgba(255,255,255,0.85);

// $background-gradient: linear-gradient(#020 5%, #000);
$background-gradient: #000 radial-gradient(ellipse at top,#4ebffb 0,#276585 70%);

$border-bottom: 1px solid $blue-dark;

// Authentification component
$auth-form-background: linear-gradient(180deg ,rgba(0,0,0,.3) 0,transparent);
$auth-form-h1: hsla(0, 0, 100%, .8);
$auth-form-input-color: $blue-blur;
$auth-form-input-border: 1px solid hsla(0, 0, 100%, .2);
$auth-form-input-background: $backround-color-black-6;
$auth-form-input-text-fill-color: #7beaf4;
$auth-form-button-bg-primary: $backround-color-black-2;

// Sidebar
$sidebar-field-border-bottom: $border-bottom;
$sidebar-field-bg-a2: $backround-color-black-2;
$sidebar-field-bg-a4: $backround-color-black-4;
$sidebar-field-bg-a6: $backround-color-black-6;
$sidebar-field-bg-a8: $backround-color-black-8;

// Libraries
$libraries-libitem-hover-gradient: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 1) 100%);

// Player Component
$player-size: 623px;
$player-header-size: 70px;
$player-offset-top: -40px;
$player-wrapper-shadow-width: 1000px;
$player-wrapper-shadow-height: 330px;
$player-wrapper-shadow-transX: translateX(calc(-50% + 157px));
$player-wrapper-shadow-image: url(../../../assets/img/circle-shadow.png);
$player-wrapper-bg: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(162,162,162,1) 100%);
$player-holder-bg: url(../../../assets/img/player_pattern.png),
  radial-gradient(ellipse at center, #d9d9d3 21%,#f3f8f8 41%,#f5f5f5 55%,#ccc 95%);
$player-wave-shadow: 0 0 2px 2px rgba(0,0,0,0.07);
$player-wave-holder-shadow: 0 0 20px 20px rgba(0,0,0,0.06);
$player-wave-inner-shadow: 0 0 2px 2px rgba(0,0,0,0.2) inset, 0 0 10px 10px #5ec7e1 inset;
$player-wave-bg-color: #5ec7e1;
$player-wave-bg-image: 
  linear-gradient(rgba(255,255,255,.2) 2px, transparent 2px),
  linear-gradient(90deg, rgba(255,255,255,.2) 2px, transparent 2px),
  linear-gradient(rgba(255,255,255,0.2) 1px, transparent 1px),
  linear-gradient(90deg, rgba(255,255,255,.2) 1px, transparent 1px),
  linear-gradient(rgba(255,255,255,.1) 1px, transparent 1px),
  linear-gradient(90deg, rgba(255,255,255,.1) 1px, transparent 1px);
$player-wave-bg-size: 90px 90px, 90px 90px, 15px 15px, 15px 15px, 5px 5px, 5px 5px;
$player-wave-bg-position: -2px 0px, 0px -2px, -1px 1px, 1px -1px, 0px -4px, -4px 0px;


// Left & Right Side Component
$side-wrapper-height: calc(100vh - 71px);
$side-category-size: 80px;
$side-category-arrow-up-top: 71px;
$side-category-arrow-down-bottom: 0px;
$side-category-shadow: 0 -1px rgba(black, .1) inset, 0 1px rgba(white, .2);
$side-sound-active-shadow: 1px 1px 1px black;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Play', sans-serif;
$font-family-monospace:       monospace;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base:              0.8125rem;
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .875;

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            600;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$font-text-shadow: rgba(100, 200, 10, 0.8)