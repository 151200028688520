@font-face {
  font-family: 'Puzzle-icons';
  src:  url('fonts/Puzzle-icons.eot?8vrrna');
  src:  url('fonts/Puzzle-icons.eot?8vrrna#iefix') format('embedded-opentype'),
    url('fonts/Puzzle-icons.woff2?8vrrna') format('woff2'),
    url('fonts/Puzzle-icons.ttf?8vrrna') format('truetype'),
    url('fonts/Puzzle-icons.woff?8vrrna') format('woff'),
    url('fonts/Puzzle-icons.svg?8vrrna#Puzzle-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="puzzle-"], [class*=" puzzle-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Puzzle-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.puzzle-fire:before {
  content: "\e91c";
}
.puzzle-puzzle:before {
  content: "\e91b";
}
.puzzle-mechanical:before {
  content: "\e914";
}
.puzzle-bubbles:before {
  content: "\e915";
}
.puzzle-snow:before {
  content: "\e916";
}
.puzzle-metal:before {
  content: "\e917";
}
.puzzle-wood:before {
  content: "\e918";
}
.puzzle-destroy:before {
  content: "\e919";
}
.puzzle-other:before {
  content: "\e91a";
}
.puzzle-wind:before {
  content: "\e900";
}
.puzzle-skillactivation:before {
  content: "\e901";
}
.puzzle-lineremove:before {
  content: "\e902";
}
.puzzle-hit:before {
  content: "\e903";
}
.puzzle-timeadd:before {
  content: "\e904";
}
.puzzle-stars:before {
  content: "\e905";
}
.puzzle-water:before {
  content: "\e906";
}
.puzzle-stone:before {
  content: "\e907";
}
.puzzle-organic:before {
  content: "\e908";
}
.puzzle-lock:before {
  content: "\e909";
}
.puzzle-ice:before {
  content: "\e90a";
}
.puzzle-gemmix:before {
  content: "\e90b";
}
.puzzle-gemfall:before {
  content: "\e90c";
}
.puzzle-swapfail:before {
  content: "\e90d";
}
.puzzle-swapok:before {
  content: "\e90e";
}
.puzzle-swap:before {
  content: "\e90f";
}
.puzzle-spell:before {
  content: "\e910";
}
.puzzle-match:before {
  content: "\e911";
}
.puzzle-jingle:before {
  content: "\e912";
}
.puzzle-destroy2:before {
  content: "\e913";
}

