@import '../../assets/styles/app/variable.scss';

.wrapper {
  position: relative;
  padding: 8px 20px;
  text-align: center;
  background: $auth-form-background;
  border-radius: 4px;

  > h1 {
    position: absolute;
    top: -0.6em; left: 50%;
    color: $auth-form-h1;
    font-size: 150px;
    transform: translateX(-50%);
  }

  > h2 {
    margin: 1rem 0;
  }

  .button {
    width: 100%;
    margin-bottom: .5rem;
    appearance: none;
    line-height: 30px;
    border-radius: 4px;
    border: none;
    background-color: $auth-form-button-bg-primary;
    color: $white;

    &.primary {
      background-color: $primary;
    }

    &.fb {
      
      background-color: $facebook;
    }

    &.google {
      background-color: $google;
    }
  }

  .divider {
    margin-top: -0.5rem;
    text-align: center;
  }

  .group {
    text-align: left;
    margin: 0.5rem 0;
  }

  input {
    line-height: 30px;
    appearance: none;
    text-align: center;
    color: $auth-form-input-color;
    border-radius: 4px;
    border: $auth-form-input-border;
    background-color: $auth-form-input-background;
    -webkit-text-fill-color: $auth-form-input-text-fill-color;
  }

  .links {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    .link {
      color: $white;
      // animation: text-color-change 0.1s infinite;
      text-decoration: none;
    
      // &:hover {
      //   color: $primary;
      //   text-decoration: underline;
      // }
    }
  }

  .error {
    font-size: $font-size-sm;
    /* text-shadow: 2px 3px 3px #a00; */
    background-color: rgba(200, 0, 0, 0.2);
    border-radius: 4px;
  }
  
}












// .form {
//   padding: 1rem;
//   text-align: center;

//   > h1 {
//     margin-top: -40px;
//     font-size: 150px;
//   }

//   > h2 {
//     margin-top: -50px;
//   }

//   input[type=email],
//   input[type=password] {

//     border: 1px solid #0a0;
//     background-color: rgba(white, .1);
//     color: #0a0;

//     &::placeholder {
//       text-transform: capitalize;
//       color: #0a0;
//       opacity: .4;
//     }

//     &:hover {
//       border: 1px solid #060;
//     }
//   }

//   .button {
//     width: 100%;
//     margin-top: 1rem;
//     background-color: #060;
//     border: none;

//     &:hover, &:active {
//       background-color: #0d0;
//     }
//   }

//   .group {
//     margin-bottom: 1rem;
//     label {
//       display: flex;
//       justify-content: start;
//       // float: left;
//     }
//   }

//   .links {
//     margin-top: 1rem;
//     display: flex;
//     justify-content: space-between;
//   }
// }

