@font-face {
  font-family: 'Farms-icons';
  src:  url('fonts/Farms-icons.eot?2glf62');
  src:  url('fonts/Farms-icons.eot?2glf62#iefix') format('embedded-opentype'),
    url('fonts/Farms-icons.woff2?2glf62') format('woff2'),
    url('fonts/Farms-icons.ttf?2glf62') format('truetype'),
    url('fonts/Farms-icons.woff?2glf62') format('woff'),
    url('fonts/Farms-icons.svg?2glf62#Farms-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="farm-"], [class*=" farm-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Farms-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.farm-milking:before {
  content: "\e915";
}
.farm-Carriage:before {
  content: "\e90b";
}
.farm-galloping:before {
  content: "\e90c";
}
.farm-neighing:before {
  content: "\e90d";
}
.farm-pig-talk:before {
  content: "\e90e";
}
.farm-pig-eating:before {
  content: "\e90f";
}
.farm-pig-screaming:before {
  content: "\e910";
}
.farm-baa:before {
  content: "\e911";
}
.farm-bells:before {
  content: "\e912";
}
.farm-cow-eating:before {
  content: "\e913";
}
.farm-moo:before {
  content: "\e914";
}
.farm-henhouse:before {
  content: "\e90a";
}
.farm-cow:before {
  content: "\e903";
}
.farm-donkey:before {
  content: "\e904";
}
.farm-eating:before {
  content: "\e905";
}
.farm-goat:before {
  content: "\e906";
}
.farm-horse:before {
  content: "\e907";
}
.farm-pig:before {
  content: "\e908";
}
.farm-sheep:before {
  content: "\e909";
}
.farm-goose:before {
  content: "\e900";
}
.farm-rooster:before {
  content: "\e901";
}
.farm-turkey:before {
  content: "\e902";
}
.farm-chicken:before {
  content: "\f700";
}

