@import '../../assets/styles/app/variable.scss';

.wrapper {
  background-color: $sidebar-field-bg-a4;

  figure {
    position: relative;
    margin: 0;
    border-bottom: $sidebar-field-border-bottom;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    background-color: $backround-color-black-2;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate3d(-50%, -50%, 0) scale(1);
      opacity: 0;
      transition: all .5s;

      &.loaded {
        opacity: 1;
      }
    }

    &:before {
      content: "";
      padding-top: 80%;
      float: left;
    }

    .shadow {
      display: flex;
      flex-direction: column;
      justify-content: end;
      width: 100%; height: 100%;
      position: absolute;
      top: 0; left: 0;
      padding-bottom: 1rem;

      text-transform: uppercase;
      text-align: center;
      color: $green;

      background: $libraries-libitem-hover-gradient;
      transition: opacity .3s cubic-bezier(0.39, 0.575, 0.565, 1);
      opacity: 0;
      z-index: 1;
    }

    &:hover {
      cursor: pointer;
      
      img {
        transform: translate3d(-50%, -50%, 0) scale(1.1);
      }

      .shadow {
        opacity: 1;
      }
    }
  }


  h1 {
    margin: .2rem .5rem;
    font-size: 1rem;
    font-weight: $font-weight-normal;
    text-transform: uppercase;
  }

  .controls {
    .buttons {
      padding: .5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      a, i, button {
        color: $green;
      }

      a, button {
        padding: 0 1.5rem;
        font-size: .8rem;
        font-weight: bold;
        line-height: 2rem;
        text-transform: uppercase;
        border-radius: 24px;
        
        border: 1px solid;
      }

      i {
        font-size: 2rem;
      }
    }
  }
}

.mobileWrapper {
  display: flex;
  background-color: $sidebar-field-bg-a4;
  position: relative;

  .skeleton {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  figure, section {
    display: inline-flex;
    vertical-align: top;
  }

  figure {
    width: 40%;
    display: inline-flex;

    img {
      width: 100%;
    }
  }

  section {
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
  }
  
  h1 {
    width: 90%;
    margin: .2rem .5rem 0;
    font-size: 1.2rem;
    // text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;
    overflow: hidden;
  }

  .controls {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .buttons {
      flex-grow: 1;
      padding: .5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      a, i, button {
        color: $green;
      }

      a, button {
        padding: 0 2rem;
        font-size: 1rem;
        font-weight: bold;
        line-height: 2rem;
        text-transform: uppercase;
        border-radius: 24px;
        
        border: 1px solid;
      }

      i {
        font-size: 2rem;
      }
    }
  }
}

.mobileInfoBar {
  h1 {
    text-align: center;
  }
  figure {
    img {
      width: 100%;
    }
  }

  .controls {
    .buttons {
      padding: .5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      a, i, button {
        color: $green;
      }

      a, button {
        padding: 0 1.5rem;
        font-size: .8rem;
        font-weight: bold;
        line-height: 2rem;
        text-transform: uppercase;
        border-radius: 24px;
        
        border: 1px solid;
      }

      i {
        font-size: 2rem;
      }
    }
  }
}