@import '../../../assets/styles/app/variable.scss';

.wrapper {
  margin: auto; 
  width: $player-size;
  height: $player-size;
  padding: 9px;
  position: relative;
  border-radius: 50%;
	background: $player-wrapper-bg;

  &:before {
    position: absolute;
    bottom: -40px;
    left: 50%;
    content: '';
    clear: both;
    display: block;
    width: $player-wrapper-shadow-width;
    height: $player-wrapper-shadow-height;
    background-image: $player-wrapper-shadow-image;
    background-repeat: no-repeat;
    background-position: bottom;
    
   
    transform: $player-wrapper-shadow-transX;
    z-index: -1;
    
  }

  > .holder {
    padding:22px;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: $player-holder-bg;

    > .inner {
      width:100%;
      height:100%;
      border-radius:50%;
      position: relative;

      .wave {
        position:absolute;
        top:50%;
        left:50%;
        padding:13px;
        width:234px;
        height:234px;
        border-radius:50%;
        transform: translate(-50%, -50%);
        box-shadow: $player-wave-shadow;

        .waveHolder {
          width: 100%;
	        height: 100%;
	        padding: 9px;
	        border-radius: 50%;
	        border: 2px solid rgba(255,255,255,1);
	        box-shadow: $player-wave-holder-shadow;
        }

        .waveInner {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 100%;
	        height: 100%;
	        border-radius: 50%;
	        box-shadow: $player-wave-inner-shadow;
	        background-color: $player-wave-bg-color;
          background-image: $player-wave-bg-image;
          background-size: $player-wave-bg-size;
          background-position: $player-wave-bg-position;
        }

        .analizer {
          width: 100%;
          height: 100%;
          position: absolute;

          canvas {
            width: 100%;
            height: 100%;
          }
        }

        .title {
          text-align: center;
          transition: opacity .3s;
          i {
            display: inherit;
            font-size: 140px;
            margin-top: -30px;
            margin-bottom: -60px;
            color: white;
          }

          h1 {
            font-size: 24px;
            color: white;
            text-transform: uppercase;
          }
        }
      }

      .timeshift {
        display: flex;
        justify-content: center;
        position: relative;
        top: -10px; left: 2px;
      }

      .sliderMiddle {
        display: flex;
        justify-content: space-between;

        .sliderMiddleLeft {
          position: relative;
          top: 14px; left: 7px;
        }

        .sliderMiddleRight {
          position: relative;
          top: 14px; right: 7px;
        }
      }

      .pitchWrappers {
        display: flex;
        justify-content: space-between;

        .pitchLeft {}

        .pitchRight {}
      }

      .sliderBottom {
        display: flex;
        justify-content: space-between;

        .sliderBottomLeft {
          position: relative;
          top: 20px; left: 56px;
        }

        .sliderBottomRight {
          position: relative;
          top: 20px; right: 56px;
        }

        .bottomControl {
          display: inherit;
          flex-direction: column;
          position: relative;
          z-index: 1;

          .btnPlay {
            position: relative;
            top: 20px;
            margin: auto;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: #e8e8e1;
            box-shadow: 0 0 0 2px rgba(0, 0, 0, .2);

            i {
              font-size: 20px;
              color: $green;
            }

            &:hover {
              cursor: pointer;
            }
          }

          .btnDownload {
            position: relative;
            top: 55px;
            padding: 1rem;
            text-transform: uppercase;
            border-radius: 25px;
            padding: 6px 20px;
            background: $background-color-white-85;
            color: $green;
            border-bottom: 3px solid $grey;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  } 

  
}