@import './variable.scss';

// Container element.
.p-progressbar {
  
  font-family: $font-family-base;
  background: $green-dark;
  border-radius: 0;
  height: 3px;
  cursor: pointer;

  // Container element of a determinate progressbar.
  .p-progressbar-determinate	{
    
  }

  // Container element of an indeterminate progressbar.
  .p-progressbar-indeterminate	{}

  // Element whose width changes according to value.
  .p-progressbar-value	{
    background: $green;
    transition: width 500ms !important;
  }

  .p-progressbar-label {
    display: none;
  }
}	

