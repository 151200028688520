@import './variable.scss';

.p-button {
  background: none;
  border: none;

  &:not(a):not(.p-disabled):hover {
    background: $green;
  }
  
  .p-button-icon {
    color: $white;
    font-size: 1.5rem;
    margin-right: -1.5rem;
  } 
  .p-button-text {} 

} 
