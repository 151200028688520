@font-face {
  font-family: 'CatDog-icons';
  src:  url('fonts/CatDog-icons.eot?oe20ff');
  src:  url('fonts/CatDog-icons.eot?oe20ff#iefix') format('embedded-opentype'),
    url('fonts/CatDog-icons.woff2?oe20ff') format('woff2'),
    url('fonts/CatDog-icons.ttf?oe20ff') format('truetype'),
    url('fonts/CatDog-icons.woff?oe20ff') format('woff'),
    url('fonts/CatDog-icons.svg?oe20ff#CatDog-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="catsNdogs-"], [class*=" catsNdogs-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'CatDog-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
  ['dog', 'dog-Yelping', 'dog-snoring', 'dog-small', 'dog-panting', 'dog-Howling','dog-eating','dog-drinking', 'dog-Crowling', 'cats', 'cats-purring', 'cats-meow', 'cats-Howling', 'cats-hisses']
*/


.catsNdogs-cats-hisses:before {
  content: "\e900";
}
.catsNdogs-cats-Howling:before {
  content: "\e901";
}
.catsNdogs-cats-meow:before {
  content: "\e902";
}
.catsNdogs-cats-purring:before {
  content: "\e903";
}
.catsNdogs-cats:before {
  content: "\e904";
}
.catsNdogs-dog-Crowling:before {
  content: "\e905";
}
.catsNdogs-dog-drinking:before {
  content: "\e906";
}
.catsNdogs-dog-eating:before {
  content: "\e907";
}
.catsNdogs-dog-Howling:before {
  content: "\e908";
}
.catsNdogs-dog-panting:before {
  content: "\e909";
}
.catsNdogs-dog-small:before {
  content: "\e90a";
}
.catsNdogs-dog-snoring:before {
  content: "\e90b";
}
.catsNdogs-dog-Yelping:before {
  content: "\e90c";
}
.catsNdogs-dog:before {
  content: "\e90d";
}

