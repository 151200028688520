// Main container element.
.p-toolbar	{
  border: none;
  background: transparent;

  .icon-diforb-logo {
    position: relative;
    top: 10px;
    font-size: 150px;
    line-height: 0px;
  }
}

// Left content container.
.p-toolbar-group-left {

}

// Right content container.
.p-toolbar-group-right {

}