.wrapper {
  .circle {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 1px solid transparent;
    border-top-color: white;
    z-index: 4000;
    animation: spin 2s linear infinite;

    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 1px solid transparent;
      border-top-color: white;
      animation: spin 3s linear infinite;
    }

    &:after {
      content: '';
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 1px solid transparent;
      border-top-color: white;
      animation: spin 1.5s linear infinite;
    }
  }

  .logo {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 5rem;
    transform: translate(-50%, -50%);
  }
}



@-webkit-keyframes spin {
  0%   {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0%   {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}