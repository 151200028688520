.dui {
  &-mt {
    &-1 {
      margin-top: .25rem;
    }
    &-2 {
      margin-top: .5rem;
    }
    &-3 {
      margin-top: .75rem;
    }
    &-4 {
      margin-top: 1rem;
    }
    &-5 {
      margin-top: 1.25rem;
    }
    &-6 {
      margin-top: 1.5rem;
    }
    &-7 {
      margin-top: 1.75rem;
    }
    &-8 {
      margin-top: 2rem;
    }
  }
  &-mb {
    &-1 {
      margin-bottom: .25rem;
    }
    &-2 {
      margin-bottom: .5rem;
    }
    &-3 {
      margin-bottom: .75rem;
    }
    &-4 {
      margin-bottom: 1rem;
    }
    &-5 {
      margin-bottom: 1.25rem;
    }
    &-6 {
      margin-bottom: 1.5rem;
    }
    &-7 {
      margin-bottom: 1.75rem;
    }
    &-8 {
      margin-bottom: 2rem;
    }
  }
}