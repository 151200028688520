@font-face {
  font-family: 'Creatures-icons';
  src:  url('fonts/Creatures-icons.eot?whq3nq');
  src:  url('fonts/Creatures-icons.eot?whq3nq#iefix') format('embedded-opentype'),
    url('fonts/Creatures-icons.woff2?whq3nq') format('woff2'),
    url('fonts/Creatures-icons.ttf?whq3nq') format('truetype'),
    url('fonts/Creatures-icons.woff?whq3nq') format('woff'),
    url('fonts/Creatures-icons.svg?whq3nq#Creatures-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="creatures-"], [class*=" creatures-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Creatures-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
  ['alien', 'goblin', 'big-monster', 'medium-monster', 'little-monster', 'death', 'attack', 'growl3', 'pain', 'talk', 'snore', 'attack-long', 'breathe', 'growl']
*/

.creatures-growl:before {
  content: "\e900";
}
.creatures-breathe:before {
  content: "\c900";
}
.creatures-attack-long:before {
  content: "\c901";
}
.creatures-snore:before {
  content: "\c902";
}
.creatures-talk:before {
  content: "\c903";
}
.creatures-pain:before {
  content: "\c904";
}
.creatures-growl3:before {
  content: "\c905";
}
.creatures-attack:before {
  content: "\c906";
}
.creatures-death:before {
  content: "\c907";
}
.creatures-little-monster:before {
  content: "\c908";
}
.creatures-medium-monster:before {
  content: "\c909";
}
.creatures-big-monster:before {
  content: "\c90a";
}
.creatures-goblin:before {
  content: "\c90b";
}
.creatures-alien:before {
  content: "\c90c";
}

