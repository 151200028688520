
@import '../../../assets/styles/app/variable.scss';

.volumeRange {
  fill: #848688;

  .volumeRangeShadow {
    // fill: #709795;
  }
}

.volumeGrad {
  .sliderGrad {
    .sliderGradInner {
      fill: #5EC7E1;
    }
  }
}

.handler {
  .handlerWrapper {
    fill: #E9E9E2;
  }
  .handlerInner {
    fill: $green-blur;
  }
  &:hover {
    cursor: pointer;
    .handlerInner {
      fill: $green;
    }
  }
}

.reverbButton {
  fill: #E9E9E2;
  
  .active {
    .reverbButtonBorder {
      fill: #4AB873;
    }
    .reverbButtonInner {
      fill: #4AB873;
    }
  }

  text {
    fill: #838383;
    font-size: 10px;
    text-transform: uppercase;
    user-select: none;  
  }

  &Border {
    fill: #838383;
    opacity: .3;
  }

  &Wrapper {
    opacity: 0.8;
  }

  &Inner {
    fill: #838383 // #4AB873
  }

  &:hover {
    cursor: pointer;
    .reverbButtonInner {
      fill: #4AB873;
    }
  }
}
