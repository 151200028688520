@font-face {
  font-family: 'Zoo-icons';
  src:  url('fonts/Zoo-icons.eot?wu3lkl');
  src:  url('fonts/Zoo-icons.eot?wu3lkl#iefix') format('embedded-opentype'),
    url('fonts/Zoo-icons.woff2?wu3lkl') format('woff2'),
    url('fonts/Zoo-icons.ttf?wu3lkl') format('truetype'),
    url('fonts/Zoo-icons.woff?wu3lkl') format('woff'),
    url('fonts/Zoo-icons.svg?wu3lkl#Zoo-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="zoo-"], [class*=" zoo-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Zoo-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zoo-wolf-howling:before {
  content: "\e909";
}
.zoo-wolf-sharl:before {
  content: "\e90a";
}
.zoo-gibbon:before {
  content: "\e908";
}
.zoo-bear:before {
  content: "\e900";
}
.zoo-elephant:before {
  content: "\e901";
}
.zoo-hippo:before {
  content: "\e902";
}
.zoo-hippo2:before {
  content: "\e903";
}
.zoo-lion1:before {
  content: "\e904";
}
.zoo-lion:before {
  content: "\e905";
}
.zoo-monkey:before {
  content: "\e906";
}
.zoo-wolf:before {
  content: "\e907";
}

