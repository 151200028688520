@import '../../../assets/styles/app/variable.scss';

// ul {
//   margin: 0;
//   padding: 0;
//   list-style: none;
// }

.leftSide {
  .categories {
    align-items: flex-start;

    .subcategory {
      left: $side-category-size;
      padding-left: 1rem;

      .scrollPanel {
        > div:nth-child(3) {
          right: 1rem !important;
        }
      }

      .subcategoryItem {
        > div {
          span {
            white-space: nowrap;
            padding-left: .5rem;
          }
        }
      }

      .sound {
        padding-left: 2rem;
        
        &:before {
          left: .7rem;
          border-left: 1px dotted $black;
        }

        &:after {
          left: .7rem;
        }
      }
    }
  }

  .categoryShadow {
    left: 0;

    &:before {
      right: 0;
    }
  }
}

.rightSide {
  .categories {
    align-items: flex-end;

    .subcategory {
      right: $side-category-size;
      padding-right: 1rem;

      .scrollPanel {
        > div:nth-child(3) {
         
          right: calc(-100% - 1rem) !important;
        }
      }

      .subcategoryItem  {
          
        > div {
          flex-direction: row-reverse;

          span {
            white-space: nowrap;
            padding-right: .5rem;
          }
        }
      }

      .sound {
        text-align: right;
        padding-right: 2rem;

        &:before {
          right: .7rem;
          border-right: 1px dotted $black;
        }

        &:after {
          right: .7rem;
        }
      }
    }
  }

  .categoryShadow {
    right: 0;

    &:before {
      left: 0;
    }
  }
}

.leftSide,
.rightSide {
  position: relative;
  // overflow: hidden;

  .categories {
    display: flex;
    flex-direction: column;

    > li {
      width: $side-category-size;
      height: $side-category-size;
      box-shadow: $side-category-shadow;

      > div {
        > span {
          width: 100%;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      
      &.active {
        > div {
          color: $green;
        }

        > .subcategory {
          visibility: visible;
        }
      }

      &:hover {
        cursor: pointer;
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: inherit;

        i {
          font-size: 2.5rem;
        }
  
        span {
          font-size: .9rem;
          text-transform: uppercase;
        }
      }

      .subcategory {
        position: absolute;
        top: 0; 
        padding-top: 1rem;
        visibility: hidden;

        .subcategoryItem  {
          box-shadow: $side-category-shadow;
          
          > div {
            position: relative;
            display: flex;
            align-items: center;
            line-height: 3rem;

            &.loading {
              span {
                &:before {
                  width: 100%;
                  transition: width 1s;
                }
              }
            }

            &.active {
              color: $green;
              text-shadow: $side-sound-active-shadow;
            }

            i {
              font-size: 1.5rem;
            }

            span {
              &:before {
                content: attr(datatype);
                position: absolute;
                display: inline-block;
                color: $green;
                width: 0;
                overflow: hidden;
                will-change: width;
              }
            }
            
          }
        }

        .sound {
          position: relative;

          span {
            position: relative;

            &.loading {
              &:before {
                width: 100%;
                transition: width 1s;
              }
            }

            &.active {
              color: $green;
              text-shadow: $side-sound-active-shadow;
            }

            &:before {
              content: attr(datatype);
              position: absolute;
              display: inline-block;
              color: $green;
              width: 0%;
              overflow: hidden;
              will-change: width;
              
            }
          }
          
          &:before, &:after {
            position: absolute;
            content: '';
            clear: both;
            display: block;
          }

          &:before {
            height: 100%;
          }

          &:after {
            top: 50%; 
            width: 1rem;
            border-top: 1px dotted $black;
          }

          &:last-child {
            &:before {
              height: 50%;
            }
          }
        }
      }
    }
  }

  .categoryShadow {
    position: absolute;
    top: 0; 
    width: $side-category-size;
    height: $side-category-size;
    background: $backround-color-black-6;
    z-index: -1;

    &:before {
      content: '';
      display: block;
      width: 3px;
      height: 100%;
      position: inherit;
      
      background: linear-gradient(180deg,#63eff5 0,#54c75e);
    }
  }

  .arrowUp,
  .arrowDown {
    position: fixed;
    width: $side-category-size;
    text-align: center;
  }

  .arrowUp {
    top: $side-category-arrow-up-top;
  }

  .arrowDown {
    bottom: $side-category-arrow-down-bottom;
  }
}

.scrollPanel {
  height: 100vh;
}