@import '../../assets/styles/app/variable.scss';

main {
  will-change: transform;
  filter: blur(0px);
  transform: scale(1) perspective(200px) translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotate3d(0, 0, 0, 0deg);
  transition: transform .3s;
  
  &.openedMenu {
    filter: blur(3px);
    transform: scale(1) perspective(200px) translate3d(0px, 0px, -30px) scale3d(1, 1, 2) rotate3d(0, 40, 0, -3deg);
  }
}

.header {
  // position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: $player-header-size;
  background-color: $backround-color-black-4;
  border-bottom: $border-bottom;
  // z-index: 100;

  .leftSide, .rightSide {
    display: inherit;
    align-items: center;

    i, span {
      padding: 0 .5rem;
    }

    i {
      font-size: 1.5rem;
    }
  }

  .leftSide {
    margin-left: 1rem;
  }

  .rightSide {
    margin-right: 1rem;
    flex-direction: row-reverse;
  }

  .switchSides {
    background-color: $backround-color-black-6;
    line-height: 1.3rem;
    padding: 0 .5rem;
    border-radius: 25px;
    button {
      cursor: pointer;

      &.active {
        color: $green
      }
    }
  }
}

.wrapper {
  position: relative;
  width: 100vw;
  height: $side-wrapper-height;
  // overflow: hidden;
  display: grid;
  grid-template-columns: [LEFT] 1fr [LEFT_SIDE_PLAYER] 650px [RIGHT_SIDE_PLAYER] 1fr [RIGHT];
  grid-template-rows: [TOP] auto [BOTTOM];

  &::before {
    content: '';
    clear: both;
    display: block;
    position: absolute;
    top: 0;
    left: $side-category-size;
    right: $side-category-size;
    bottom: 0;
    background: linear-gradient(180deg, $backround-color-black-4 0, transparent 50%);
  }

  .leftSide {
    // opacity: 0;
    grid-column-start: [LEFT];
    grid-column-end: [LEFT_SIDE_PLAYER];
    grid-row-start: [TOP];
    grid-row-end: [BOTTOM];
    // margin-top: $player-header-size;
  }

  .rightSide {
    // opacity: 0;
    grid-column-start: [RIGHT_SIDE_PLAYER];
    grid-column-end: [RIGHT];
    grid-row-start: [TOP];
    grid-row-end: [BOTTOM];
    // margin-top: $player-header-size;
  }

  .player {
    grid-column-start: [LEFT_SIDE_PLAYER];
    grid-column-end: [RIGHT_SIDE_PLAYER];
    grid-row-start: [TOP];
    grid-row-end: [BOTTOM];
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: $player-offset-top;
  }
}

.menu {
  background-color: $backround-color-black-4 !important;

  [class='p-sidebar-header'] {
    display: none !important;
  }

  [class='p-sidebar-header'],
  [class='p-sidebar-content'] {
    padding: 0 !important;
    display: flex;

    > div {
      width: 300px;
    }
  }
}
