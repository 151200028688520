@import '../../assets/styles/app/variable.scss';

.wrapper {
  margin-top: 1rem;
  padding: 1rem;

  > div {
    max-width: 360px;
    margin: auto;
  }

}